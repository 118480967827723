import React from "react";
import "./SkillCard.css";

const SkillCard = ({ skill }) => {
  return (
    <div className="skill-card">
      <div>
        <img src={skill.img1} alt="skill-img" />
      </div>
      <p>{skill.title}</p>
    </div>
  );
};

export default SkillCard;
