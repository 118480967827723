const otherSkills = [
  {
    id: 1,
    title: 'Git',

    img1: '/images/skillsImages/others/git.png',
  },
  {
    id: 2,
    title: 'GitHub',
    img1: '/images/skillsImages/others/github.png',
  },
  {
    id: 3,
    title: 'Bitbucket',
    img1: '/images/skillsImages/others/bitbucket.png',
  },
  {
    id: 4,
    title: 'Firebase',
    img1: '/images/skillsImages/others/firebase.png',
  },
  {
    id: 5,
    title: 'AWS',
    img1: '/images/skillsImages/others/aws1.png',
  },
  {
    id: 6,
    title: 'Linux',
    img1: '/images/skillsImages/others/linux.png',
  },
  {
    id: 7,
    title: 'WordPress',
    img1: '/images/skillsImages/others/wordpress.png',
  },
  {
    id: 8,
    title: 'Elementor',
    img1: '/images/skillsImages/others/elementor.png',
  },
  {
    id: 9,
    title: 'Slack',
    img1: '/images/skillsImages/others/slack.png',
  },
  {
    id: 10,
    title: 'Trello',
    img1: '/images/skillsImages/others/trello.png',
  },
  {
    id: 11,
    title: 'Jira',
    img1: '/images/skillsImages/others/jira.png',
  },
  {
    id: 12,
    title: 'Asana',
    img1: '/images/skillsImages/others/asana.png',
  },
  {
    id: 13,
    title: 'VS Code',
    img1: '/images/skillsImages/others/vsCode.png',
  },
];

export default otherSkills;
