import React, { useEffect } from "react";
import "./BlogDataStructure.css";

const BlogDataStructure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-page-body">
      <div className="container blog-content-body">
        {/* Blog Title */}
        <div className="blog-title">
          <h1>Data Structure Blog</h1>
        </div>

        {/* Blog Content  */}
        <div className="blog-content">
          <h5 className="mt-4">Data Structures</h5>
          <p>
            Data structures are techniques for storing and organizing data that
            make it easier to modify, navigate, and access. Data structures
            determine how data is collected, the functions we can use to access
            it, and the relationships between data.
          </p>

          <h5 className="mt-4">Benefits of Data Structures</h5>
          <p>Data structures enable us to:</p>
          <ul>
            <li>Manage and utilize large datasets</li>
            <li>Search for particular data from a database</li>
            <li>
              Design algorithms that are tailored towards particular programs
            </li>
            <li>Handle multiple requests from users at once</li>
            <li>Simplify and speed up data processing</li>
          </ul>

          <h5 className="mt-4">JavaScript data structure types:</h5>
          <ul>
            <li>
              Primitive data Structures (Native to the programming language)
            </li>
            <ul>
              <li>boolean</li>
              <li>null</li>
              <li>number</li>
              <li>string</li>
            </ul>
            <li>
              Non-primitive data structures (Not defined by the programming
              language but rather by the programmer)
            </li>
            <ul>
              <li>Linear data structures</li>
              <li>Static data structures</li>
              <li>Dynamic data structures</li>
            </ul>
          </ul>

          <h5 className="mt-4">JavaScript Data Structure Examples:</h5>

          <h5 className="mt-3">Array</h5>
          <p>
            The most basic of all data structures, an array stores data in
            memory for later use. Each array has a fixed number of cells decided
            on its creation, and each cell has a corresponding numeric index
            used to select its data. You can access any of the data within the
            array using the desired indices.
          </p>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>Simple to create and use.</li>
            <li>Foundational building block for complex data structures</li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>Fixed size</li>
            <li>Expensive to insert/delete or resequence values</li>
            <li>Inefficient to sort</li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>Basic spreadsheets</li>
            <li>Within complex structures such as hash tables</li>
          </ul>

          <h5 className="mt-3">Queues</h5>
          <p>
            Queues can be thought of as a FIFO (First In, First Out) version of
            stacks. These are helpful as a buffer for requests.
          </p>
          <p>
            For a visual, consider a single-lane tunnel: the first car to enter
            is the first car to exit. If other cars should wish to exit, but the
            first stops, all cars will have to wait for the first to exit before
            they can proceed.
          </p>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>Dynamic size</li>
            <li>Orders data in the order it was received</li>
            <li>Low runtime</li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>Can only retrieve the oldest element</li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>Effective as a buffer when receiving frequent data</li>
            <li>
              Convenient way to store order-sensitive data such as stored
              voicemails
            </li>
            <li>Ensures the oldest data is processed first</li>
          </ul>

          <h5 className="mt-3">Linked List</h5>
          <p>
            Linked lists are a data structure which, unlike the previous three,
            does not use physical placement of data in memory. This means that,
            rather than indexes or positions, linked lists use a referencing
            system: elements are stored in nodes that contain a pointer to the
            next node, repeating until all nodes are linked. This system allows
            efficient insertion and removal of items without the need for
            reorganization.
          </p>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>Efficient insertion and removal of new elements</li>
            <li>Less complex than restructuring an array</li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>Uses more memory than arrays</li>
            <li>Inefficient to retrieve a specific element</li>
            <li>Inefficient to traverse the list backwards</li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>
              Best used when data must be added and removed in quick succession
              from unknown locations
            </li>
          </ul>

          <h5 className="mt-3">Trees</h5>
          <p>Trees are another relation-based data structure.</p>
          <p>
            Each tree has a “root” node, off of which all other nodes branch.
            The root contains references to all elements directly below it,
            which are known as its “child nodes”. This continues, with each
            child node, branching off into more child nodes.
          </p>
          <p>
            Nodes with linked child nodes are called internal nodes while those
            without child nodes are external nodes. A common type of tree is the
            “binary search tree” which is used to easily search stored data.
            These search operations are highly efficient, as its search duration
            is dependent not on the number of nodes but on the number of levels
            down the tree.
          </p>
          <p>This type of tree is defined by four strict rules:</p>
          <ol>
            <li>
              The left subtree contains only nodes with elements lesser than the
              root.
            </li>
            <li>
              The right subtree contains only nodes with elements greater than
              the root.
            </li>
            <li>
              Left and right subtrees must also be a binary search tree. They
              must follow the above rules with the “root” of their tree.
            </li>
            <li>
              There can be no duplicate nodes, i.e. no two nodes can have the
              same value.
            </li>
          </ol>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>Ideal for storing hierarchical relationships</li>
            <li>Dynamic size</li>
            <li>Quick at insert and delete operations</li>
            <li>
              In a binary search tree, inserted nodes are sequenced immediately.
            </li>
            <li>
              Binary search trees are efficient at searches; length is only
              O(height).
            </li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>Slow to rearrange nodes</li>
            <li>Child nodes hold no information about their parent node</li>
            <li>
              Binary search trees are not as fast as the more complicated hash
              table
            </li>
            <li>
              Binary search trees can degenerate into linear search (scanning
              all elements) if not implemented with balanced subtrees.
            </li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>Storing hierarchical data such as a file location.</li>
            <li>
              Binary search trees are excellent for tasks needing searching or
              ordering of data.
            </li>
          </ul>

          <h5 className="mt-3">Graphs</h5>
          <p>
            Graphs are a relation-based data structure helpful for storing
            web-like relationships. Each node, or vertex, as they’re called in
            graphs, has a title (A, B, C, etc.), a value contained within, and a
            list of links (called edges) it has with other vertices.
          </p>
          <p>
            In the above example, each circle is a vertex, and each line is an
            edge. If produced in writing, this structure would look like:
          </p>
          <p>V = &#x7b;a, b, c, d&#x7d;</p>
          <p>E = &#x7b;ab, ac, bc, cd&#x7d;</p>
          <p>
            While hard to visualize at first, this structure is invaluable in
            conveying relationship charts in textual form, anything from
            circuitry to train networks.
          </p>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>Can quickly convey visuals over text</li>
            <li>
              Usable to model a diverse number of subjects so long as they
              contain a relational structure
            </li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>
              At a higher level, text can be time-consuming to convert to an
              image.
            </li>
            <li>
              It can be difficult to see the existing edges or how many edges a
              given vertex has connected to it
            </li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>Network representations</li>
            <li>Modeling social networks, such as Facebook.</li>
          </ul>

          <h5 className="mt-3">Hash Tables (Map)</h5>
          <p>
            Hash tables are a complex data structure capable of storing large
            amounts of information and retrieving specific elements efficiently.
            This data structure relies on the concept of key/value pairs, where
            the “key” is a searched string and the “value” is the data paired
            with that key.
          </p>
          <p>
            Each searched key is converted from its string form into a numerical
            value, called a hash, using a predefined hash function. This hash
            then points to a storage bucket — a smaller subgroup within the
            table. It then searches the bucket for the originally entered key
            and returns the value associated with that key.
          </p>
          <p>
            <strong>Advantages</strong>
          </p>
          <ul>
            <li>
              Key can be in any form, while array’s indices must be integers
            </li>
            <li>Highly efficient search function</li>
            <li>Constant number of operations for each search</li>
            <li>Constant cost for insertion or deletion operations</li>
          </ul>
          <p>
            <strong>Disadvantages</strong>
          </p>
          <ul>
            <li>
              Collisions: an error caused when two keys convert to the same hash
              code or two hash codes point to the same value.
            </li>
            <li>
              These errors can be common and often require an overhaul of the
              hash function.
            </li>
          </ul>
          <p>
            <strong>Applications</strong>
          </p>
          <ul>
            <li>Database storage</li>
            <li>Address lookups by name</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogDataStructure;
