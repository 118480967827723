import React from 'react';
import './About.css';
import aboutPic from '../../images/masrur-sakib-suited-navy.png';

const About = () => {
  return (
    <div id='about'>
      <div className='container'>
        <h1 className='section-title'>About Me</h1>
        <div className='about-me-section-container'>
          <div className='about-me-section-image-container'>
            <div className='about-me-section-image-section'>
              <img className='about-pic' src={aboutPic} alt=''></img>
            </div>
          </div>
          <div className='col-md-6 about-me-description'>
            <p>
              Hi there, I am a passionate Full Stack Engineer. I specialize in
              JavaScript and have professional experience working with React.js,
              Node.js, Express.js. I continuously learn new technologies and
              keep myself up to date with industry advancements. I enjoy solving
              problems and working in a team.
            </p>
            <div className='experience-section'>
              <div className='experience-section-title-row'>
                <p>
                  <strong>Most Recent Experiences:</strong>
                </p>
                <a
                  className='experience-details-link'
                  href='https://www.linkedin.com/in/masrur-sakib/'
                  target='_blank'
                  rel='noreferrer'
                >
                  View Details
                </a>
              </div>
              <div className='row experience-row'>
                <p className='col-lg-6'>Sep 2024 - Present:</p>
                <p className='col-lg-6 experience-designation'>
                  Software Engineer at Valt
                </p>
              </div>
              <div className='row experience-row'>
                <p className='col-lg-6'>Mar 2023 - Feb 2024:</p>
                <p className='col-lg-6 experience-designation'>
                  Software Engineer at Wudpecker
                </p>
              </div>
              <div className='row experience-row'>
                <p className='col-lg-6'>Oct 2021 - Nov 2022:</p>
                <p className='col-lg-6 experience-designation'>
                  Senior Software Engineer at Tortalabz Innovations
                </p>
              </div>
              <div className='row experience-row'>
                <p className='col-lg-6'>Jun 2020 - Aug 2021:</p>
                <p className='col-lg-6 experience-designation'>
                  Software Engineer at Expert Consortium Ltd
                </p>
              </div>
              <div className='row experience-row'>
                <p className='col-lg-6'>Sep 2019 - Jan 2020:</p>
                <p className='col-lg-6 experience-designation'>
                  Web Content Developer at Staff Asia
                </p>
              </div>
            </div>
            <div className='experience-section'>
              <div className='experience-section-title-row'>
                <p>
                  <strong>Academic Qualification:</strong>
                </p>
              </div>
              <div className='academic-row'>
                <p className='university-name'>
                  Shahjalal University of Science &amp; Technology
                </p>
                <p className='program-name'>
                  Master's in Information Technology
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
