import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
const Header = () => {
  return (
    <div className='header-section'>
      <nav className='navbar navbar-expand-md navbar-dark fixed-top site-navbar'>
        <div className='container'>
          <Link
            className='navbar-brand'
            to='/'
            style={{ textDecoration: 'none' }}
          >
            <span
              className='site-title'
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Masrur Sakib
            </span>
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavAltMarkup'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div
            className='navbar-collapse collapse justify-content-end'
            id='navbarNavAltMarkup'
          >
            <ul className='navbar-nav'>
              <li className='nav-item active'>
                <a className='nav-link' aria-current='true' href='#home'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#about'>
                  About
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#skills'>
                  Skills
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#projects'>
                  Projects
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#blog'>
                  Blogs
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#contact'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
