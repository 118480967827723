import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <p id="footer-text">
          <span>
            <strong>&copy; </strong>
          </span>
          All Rights Reserved to
          <span id="name-in-footer"> MASRUR SAKIB</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
