import React, { useEffect } from "react";
import "./BlogReact.css";

const BlogReact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-page-body">
      <div className="container blog-content-body">
        {/* Blog Title */}
        <div className="blog-title">
          <h1>React Blog</h1>
        </div>

        {/* Blog Content  */}
        <div className="blog-content">
          <h5 className="mt-4">React</h5>
          <p>
            React is a way to build user interfaces. It is only concerned with
            what you see on the front-end. React makes user interfaces very easy
            to build by cutting each page into pieces. We call these pieces
            components.
          </p>

          <h5 className="mt-4">React Component</h5>
          <p>
            A React component is a bit of code that represents a piece of the
            page. Each component is a JavaScript function that returns a piece
            of code that represents a piece of a web page. To build a page, we
            call these functions in a certain order, put together the result,
            and show it to the user.
          </p>

          <h5 className="mt-4">Virtual DOM</h5>
          <p>
            The virtual DOM (VDOM) is a programming concept where an ideal, or
            “virtual”, representation of a UI is kept in memory and synced with
            the “real” DOM by a library such as ReactDOM. This process is called
            reconciliation.
          </p>
          <p>
            This approach enables the declarative API of React: You tell React
            what state you want the UI to be in, and it makes sure the DOM
            matches that state. This abstracts out the attribute manipulation,
            event handling, and manual DOM updating that you would otherwise
            have to use to build your app.
          </p>

          <h5 className="mt-4">React Hooks</h5>
          <p>
            Hooks are a new addition in React 16.8. They let you use state and
            other React features without writing a class. To enable Hooks, all
            React packages need to be 16.8.0 or higher. You can’t use Hooks
            inside a class component, but you can definitely mix classes and
            function components with Hooks in a single tree.
          </p>

          <h3 className="mt-4">Some Useful hooks</h3>
          <h5 className="mt-4">useState</h5>
          <p>Returns a stateful value, and a function to update it.</p>

          <pre className="code-block">
            const [state, setState] = useState(initialState);
          </pre>
          <p>
            During the initial render, the returned state (state) is the same as
            the value passed as the first argument (initialState). The setState
            function is used to update the state. It accepts a new state value
            and enqueues a re-render of the component.
          </p>
          <pre className="code-block">setState(newState);</pre>
          <p>
            During subsequent re-renders, the first value returned by useState
            will always be the most recent state after applying updates.
          </p>

          <h5 className="mt-4">useEffect</h5>
          <p>
            Accepts a function that contains imperative, possibly effectful
            code.
          </p>
          <pre className="code-block">useEffect(didUpdate);</pre>
          <p>
            Mutations, subscriptions, timers, logging, and other side effects
            are not allowed inside the main body of a function component
            (referred to as React’s render phase). Doing so will lead to
            confusing bugs and inconsistencies in the UI.
          </p>
          <p>
            Instead, use useEffect. The function passed to useEffect will run
            after the render is committed to the screen. Think of effects as an
            escape hatch from React’s purely functional world into the
            imperative world.
          </p>
          <p>
            By default, effects run after every completed render, but you can
            choose to fire them only when certain values have changed.
          </p>

          <h5 className="mt-4">useContext</h5>
          <pre className="code-block">const value = useContext(MyContext);</pre>
          <p>
            Accepts a context object (the value returned from
            React.createContext) and returns the current context value for that
            context. The current context value is determined by the value prop
            of the nearest above the calling component in the tree.
          </p>
          <p>
            When the nearest above the component updates, this Hook will trigger
            a rerender with the latest context value passed to that MyContext
            provider. Even if an ancestor uses React.memo or
            shouldComponentUpdate, a rerender will still happen starting at the
            component itself using useContext.
          </p>
          <p>The argument to useContext must be the context object itself:</p>
          <p>
            <strong>Correct:</strong> useContext(MyContext)
          </p>
          <p>
            <strong>Incorrect:</strong> useContext(MyContext.Consumer)
          </p>
          <p>
            <strong>Incorrect:</strong> useContext(MyContext.Provider)
          </p>
          <p>
            A component calling useContext will always re-render when the
            context value changes. If re-rendering the component is expensive,
            you can optimize it by using memoization.
          </p>

          <h5 className="mt-4">useReducer</h5>
          <p>
            An alternative to useState. Accepts a reducer of type (state,
            action) =&gt; newState, and returns the current state paired with a
            dispatch method.
          </p>
          <pre className="code-block">
            const [state, dispatch] = useReducer(reducer, initialArg, init);
          </pre>
          <p>
            useReducer is usually preferable to useState when you have complex
            state logic that involves multiple sub-values or when the next state
            depends on the previous one. useReducer also lets you optimize
            performance for components that trigger deep updates because you can
            pass dispatch down instead of callbacks.
          </p>

          <h5 className="mt-4">useCallback</h5>
          <p>Returns a memoized callback.</p>
          <pre className="code-block">
            const memoizedCallback = useCallback( () =&gt; &#x7b;
            <br />
            doSomething(a, b);
            <br />
            &#x7d;,
            <br />
            [a, b],
            <br />
            );
          </pre>
          <p>
            Pass an inline callback and an array of dependencies. useCallback
            will return a memoized version of the callback that only changes if
            one of the dependencies has changed. This is useful when passing
            callbacks to optimized child components that rely on reference
            equality to prevent unnecessary renders (e.g.
            shouldComponentUpdate).
          </p>

          <h5 className="mt-4">useRef</h5>
          <pre className="code-block">
            const refContainer = useRef(initialValue);
          </pre>
          <p>
            useRef returns a mutable ref object whose .current property is
            initialized to the passed argument (initialValue). The returned
            object will persist for the full lifetime of the component.
          </p>

          <h5 className="mt-4">Node</h5>
          <p>
            Node.js is an open-source server environment, is free, runs on
            various platforms (Windows, Linux, Unix, Mac OS X, etc.), uses
            JavaScript on the server.
          </p>
          <p>
            A common task for a web server can be to open a file on the server
            and return the content to the client.
          </p>
          <p>
            Node.js runs single-threaded, non-blocking, asynchronously
            programming, which is very memory efficient.
          </p>

          <h5 className="mt-4">JSX</h5>
          <p>
            JSX stands for JavaScript XML, allows writing HTML &amp; JavaScript
            simultaneously.
          </p>
          <p>
            JSX makes it easier to write and add HTML in React. JSX allows us to
            write HTML elements in JavaScript and place them in the DOM without
            any createElement() and/or appendChild() methods. JSX converts HTML
            tags into react elements.
          </p>

          <h5 className="mt-4">Babel</h5>
          <p>
            Babel is a toolchain that is mainly used to convert ECMAScript 2015+
            code into a backwards compatible version of JavaScript in current
            and older browsers or environments. Here are the main things Babel
            can do for you:
          </p>
          <ul>
            <li>Transform syntax</li>
            <li>
              Polyfill features that are missing in your target environment
            </li>
            <li>Source code transformations</li>
          </ul>
          <p>
            Babel is a JavaScript compiler that includes the ability to compile
            JSX into regular JavaScript.
          </p>

          <h5 className="mt-4">Webpack</h5>
          <p>
            Webpack is a popular module bundling system built on top of Node.js.
            It can handle not only combination and minification of JavaScript
            and CSS files, but also other assets such as image files (spriting)
            through the use of plugins.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogReact;
