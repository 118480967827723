import React, { useState } from "react";
import "./Projects.css";
import projectsData from "../../Data/projectsData/projectsData";
import Project from "../Project/Project";

const Projects = () => {
  const [projects] = useState(projectsData);

  return (
    <div id="projects">
      <div className="container">
        <h1 className="section-title">Projects</h1>
        <div className="projects-section">
          {projects.map((project) => {
            return <Project key={project.id} project={project}></Project>;
          })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
