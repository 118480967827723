import React from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_5qn6xkx',
        'template_m8uabev',
        e.target,
        'user_xYaO9EkNJILhj1KEfoHGO'
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById('successMessage').innerHTML =
            'Your message has been submitted successfully.';
          setTimeout(function () {
            document.getElementById('successMessage').innerHTML = '';
          }, 4000);
        },
        (error) => {
          console.log(error.text);
          document.getElementById('errorMessage').innerHTML = error.text;
          setTimeout(function () {
            document.getElementById('errorMessage').innerHTML = '';
          }, 4000);
        }
      );
    e.target.reset();
  }

  return (
    <div id='contact'>
      <div className='container'>
        <h1 className='section-title'>Contact</h1>
        <div className='contact-form'>
          <form onSubmit={sendEmail}>
            <div className='form-floating mb-3'>
              <input
                type='text'
                className='form-control'
                id='floatingName'
                name='name'
                placeholder='Name'
                required
              ></input>
              <label htmlFor='floatingName'>Name</label>
            </div>
            <div className='form-floating mb-3'>
              <input
                type='email'
                className='form-control'
                id='floatingEmail'
                name='email'
                placeholder='Email address'
                required
              ></input>
              <label htmlFor='floatingEmail'>Email address</label>
            </div>
            <div className='form-floating mb-3'>
              <input
                type='number'
                className='form-control'
                id='floatingPhone'
                name='phone'
                placeholder='Phone Number'
              ></input>
              <label htmlFor='floatingPhone'>Phone Number</label>
            </div>
            <div className='form-floating'>
              <textarea
                type='text'
                className='form-control'
                placeholder='Message'
                id='floatingMessage'
                name='message'
                style={{ height: 100 }}
              ></textarea>
              <label htmlFor='floatingMessage' required>
                Message / Query{' '}
              </label>
            </div>
            <div className='d-grid gap-2 mt-3'>
              <button
                className='btn form-submit-button'
                type='submit'
                value='Send'
              >
                Submit
              </button>
            </div>
          </form>
          <div className='mt-3'>
            <p id='successMessage' className='text-center'></p>
            <p id='errorMessage' className='text-danger text-center'></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
