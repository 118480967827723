import React from 'react';
import About from '../About/About';
// import Blogs from '../Blogs/Blogs';
import Contact from '../Contact/Contact';
import Home from '../Home/Home';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';
import Footer from '../Footer/Footer';
import './HomePage.css';

const HomePage = () => {
  return (
    <div id='home'>
      <div className='homepage'>
        <Home></Home>
        <About></About>
        <Skills></Skills>
        <Projects></Projects>
        {/* <Blogs></Blogs> */}
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default HomePage;
