import React from 'react';
import './Skills.css';
import frontendSkills from '../../Data/skills/frontendSkills';
import backendSkills from '../../Data/skills/backendSkills';
import otherSkills from '../../Data/skills/otherSkills';
import SkillCard from '../SkillCard/SkillCard';

const Skills = () => {
  return (
    <div id='skills'>
      <div className='container'>
        <h1 className='section-title'>Professional Skills</h1>
        <div className='skills-section'>
          <div className='skills frontend-skills'>
            <h4>Frontend Skills</h4>
            <div className='skills-card-section'>
              {frontendSkills.map((skill, index) => (
                <SkillCard key={skill.id} skill={skill} />
              ))}
            </div>
          </div>
          <div className='skills backend-skills'>
            <h4>Backend Skills</h4>
            <div className='skills-card-section'>
              {backendSkills.map((skill, index) => (
                <SkillCard key={skill.id} skill={skill} />
              ))}
            </div>
          </div>
          <div className='skills other-skills'>
            <h4>Other Skills</h4>
            <div className='skills-card-section'>
              {otherSkills.map((skill, index) => (
                <SkillCard key={skill.id} skill={skill} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
