import React, { useEffect } from "react";
import "./BlogJavaScript.css";

const BlogJavaScript = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-page-body">
      <div className="container blog-content-body">
        {/* Blog Title */}
        <div className="blog-title">
          <h1>JavaScript Blog</h1>
        </div>

        {/* Blog Content  */}
        <div className="blog-content">
          <h5 className="mt-4">JavaScript Types:</h5>
          <ul>
            <li>Number</li>
            <li>String</li>
            <li>Boolean</li>
            <li>Symbol (New in ES2015)</li>
            <li>Object</li>
            <ul>
              <li>Function</li>
              <li>Array</li>
              <li>Date</li>
              <li>RegExp</li>
            </ul>
            <li>null</li>
            <li>undefined</li>
          </ul>

          <h5 className="mt-4">
            The &lt;fieldset&gt; and &lt;legend&gt; Element
          </h5>
          <p>
            The &lt;fieldset&gt; element is a convenient way to create groups of
            widgets that share the same purpose, for styling and semantic
            purposes.
          </p>
          <p>
            The text content of the &lt;legend&gt; formally describes the
            purpose of the &lt;fieldset&gt; it is included inside.
          </p>

          <h5 className="mt-4">Cross Browser Testing?</h5>
          <p>
            Cross Browser Testing is the practice of making sure that the web
            sites and web apps you create work across an acceptable number of
            web browsers.
          </p>
          <p>
            As a web developer, you need to test your work by following ways:
          </p>
          <ul>
            <li>
              Test your work in different browsers other than the one or two
              that you use regularly on your devices, including slightly older
              browsers that some people might still be using.
            </li>
            <li>
              Test your work in different devices with different capabilities,
              from the latest greatest tablets and smartphones, through smart
              TVs, right down to cheap tablets and even older feature phones
              that may run browsers with limited capabilities.
            </li>
            <li>
              Also keep that in mind that people with disabilities, who use the
              Web with the aid of assistive technologies like screen readers or
              don’t use a mouse (some people use only the keyboard).
            </li>
          </ul>

          <h5 className="mt-4">Reasons behind Cross Browser Issues</h5>
          <ul>
            <li>
              Sometimes browsers have bugs or implement features differently.
            </li>
            <li>
              Some browsers may have different levels of support for technology
              features than others. This is inevitable when you are dealing with
              newly developed features that browsers are just getting round to
              implementing, or if you have to support really old browsers that
              are no longer being developed.
            </li>
            <li>
              Some devices may have constraints that cause a web site to run
              slowly or display badly. For example, if a site has been designed
              to look nice on a desktop PC, it will probably look tiny and be
              hard to read on a mobile device. If your site includes a load of
              big animations, it might be ok on a high spec tablet, but might
              look bad on a low-end device.
            </li>
          </ul>

          <h5 className="mt-4">Workflows For Cross Browser Testing</h5>
          <p>
            Initial planning &gt; Development &gt; Testing/discovery &gt;
            Fixes/iteration
          </p>

          <h5 className="mt-4">Client-Side Storage</h5>
          <p>
            Client-side storage consists of JavaScript APIs that used to store
            data on the client (user’s PC) and then retrieve it when needed.
            This has many uses, such as:
          </p>
          <ul>
            <li>
              Personalizing site preferences (e.g. showing a user’s choice of
              custom widgets, colour scheme, or font size).
            </li>
            <li>
              Persisting previous site activity (e.g. storing the contents of a
              shopping cart from a previous session, remembering if a user was
              previously logged in).
            </li>
            <li>
              Saving data and assets locally so a site will be quicker (and
              potentially less expensive) to download, or be usable without a
              network connection.
            </li>
            <li>
              Saving web application generated documents locally for use
              offline.
            </li>
          </ul>

          <h5 className="mt-4">Coding Style:</h5>
          {/* <img src={CodingStyleImg} alt=""></img> */}

          <h5 className="mt-4">Indentation Rule</h5>
          <p>
            There should not be more than nine lines of code without a vertical
            indentation.
          </p>

          <h5 className="mt-4">Where to comment &amp; Where to not</h5>
          <h6>Comment this:</h6>
          <ul>
            <li>The overall architecture, high-level view.</li>
            <li>Function usage.</li>
            <li>Important solutions</li>
          </ul>
          <h6>Avoid comments:</h6>
          <ul>
            <li>That tells “how code works” and “what it does”.</li>
            <li>
              To make the code very easy to understand where code itself is
              self-descriptive.
            </li>
          </ul>

          <h5 className="mt-4">Why Not to Use eval</h5>
          <p>
            eval only executes in the local scope when it is being called
            directly and when the name of the called function is actually eval.
          </p>
          <p>
            The use of eval should be avoided. 99.9% of its “uses” can be
            achieved without it.
          </p>

          <h5 className="mt-4">undefined and null</h5>
          <p>Here are some examples of when the value undefined is returned:</p>
          <ul>
            <li>Accessing the (unmodified) global variable undefined.</li>
            <li>Accessing a declared but not yet initialized variable.</li>
            <li>
              Implicit returns of functions due to missing return statements.
            </li>
            <li>return statements that do not explicitly return anything.</li>
            <li>Lookups of non-existent properties.</li>
            <li>
              Function parameters that do not have any explicit value passed.
            </li>
            <li>Anything that has been set to the value of undefined.</li>
            <li>Any expression in the form of void(expression)</li>
          </ul>

          <h5 className="mt-4">JavaScript Scope</h5>
          <p>
            Scope determines the accessibility (visibility) of variables. In
            JavaScript there are two types of scope:
          </p>
          <ul>
            <li>Local scope</li>
            <li>Global scope</li>
          </ul>
          <h6>Local JavaScript Variables</h6>
          <p>
            Variables declared within a JavaScript function, become LOCAL to the
            function. Local variables have Function scope: They can only be
            accessed from within the function.
          </p>
          <h6>Global JavaScript Variables</h6>
          <p>
            A variable declared outside a function becomes GLOBAL. A global
            variable has global scope. All scripts and functions on a web page
            can access it.
          </p>

          <h5 className="mt-4">Let Declarations (let)</h5>
          <p>
            The let declaration syntax is the same as the syntax for var. You
            can basically replace var with let to declare a variable, but limit
            the variable’s scope to only the current code block.
          </p>

          <h5 className="mt-4">Constant Declarations (const)</h5>
          <p>
            You can also define variables in ES 6 with the const declaration
            syntax. Variables declared using const are considered constants,
            meaning their values cannot be changed once set.
          </p>

          <h5 className="mt-4">The Temporal Dead Zone</h5>
          <p>
            A variable declared with either let or const cannot be accessed
            until after the declaration. Attempting to do so results in a
            reference error.
          </p>
          <pre className="code-block">
            if (condition) &#x7b;
            <br />
            console.log(typeof value); // ReferenceError!
            <br />
            let value = "blue";
            <br />
            &#x7d;
          </pre>

          <h5 className="mt-4">Object Oriented Programming (OOP):</h5>
          <p>
            Object-oriented programming (OOP) is a computer programming model
            that organizes software design around data, or objects, rather than
            functions and logic. The main aim of OOP is to bind together the
            data and the functions that operate on them so that no other part of
            the code can access this data except that function.
          </p>

          <h5 className="mt-4">4 Pillars of OOP:</h5>
          <ul>
            <li>Abstraction</li>
            <li>Encapsulation</li>
            <li>Inheritance</li>
            <li>Polymorphism</li>
          </ul>

          <h5 className="mt-4">Abstraction</h5>
          <p>
            Abstraction is a process where you show only relevant data and hide
            unnecessary details of an object from the user.
          </p>

          <h5 className="mt-4">Encapsulation:</h5>
          <p>
            Encapsulation is Binding the data with the code that manipulates it,
            It keeps the data and the code safe from external interference.
          </p>

          <h5 className="mt-4">Inheritance:</h5>
          <p>
            Inheritance is the mechanism by which an object acquires the
            some/all properties of another object. It supports the concept of
            hierarchical classification.
          </p>

          <h5 className="mt-4">Polymorphism:</h5>
          <p>
            Polymorphism means to process objects differently based on their
            data type or class. In other words, it means one method with
            multiple implementations, for a certain class of action. And which
            implementation to be used is decided at runtime depending upon the
            situation (i.e., data type of the object).
          </p>

          <h5 className="mt-4">Benefits of OOP:</h5>
          <ul>
            <li>
              Objects modelled on real-world things are the centrepiece of any
              OOP-based application.
            </li>
            <li>Encapsulation protects data from uncontrolled access.</li>
            <li>
              Objects have functions that operate on the data the objects
              contain.
            </li>
            <li>Classes are the templates used to instantiate objects.</li>
            <li>Inheritance is a powerful tool for avoiding redundancy.</li>
            <li>
              OOP is more verbose but easier to read than other coding
              paradigms.
            </li>
            <li>
              Since OOP came later in JavaScript’s development, you may come
              across older code that uses prototype or functional programming
              techniques.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogJavaScript;
