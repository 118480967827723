const projectsData = [
  {
    id: 1,
    title: "Aircnc v2",
    // img1: 'https://i.ibb.co/mHq6FgB/redux-aircnc-min.png',
    img1: "/images/projectsImages/aircnc.png",
    description:
      "This is mainly developed with typescript, Next.js, Tailwind etc. Backend developed with Prisma. MongoDB is Used as for data storage. Authentication can be done with Google & GitHub sign in.",
    // technology:
    //   "React.js, Redux, React Router, JavaScript, Node.js, MongoDB, Heroku, Firebase, HTML, CSS, Bootstrap.",
    technology: [
      "Next.js",
      "TypeScript",
      "Tailwind",
      "Prisma",
      "Next Auth",
      "MongoDB",
      "Bcrypt",
      "Vercel",
    ],
    siteLink: "https://masrrursakib-aircnc-masrur-sakib.vercel.app/",
    githubLink: "https://github.com/masrur-sakib/aircnc",
    disabled: "link",
  },
  {
    id: 2,
    title: "SpaceX",
    // img1: 'https://i.ibb.co/vdyfrXH/fullstack-photography-project-min.png',
    img1: "/images/projectsImages/spacex-md.png",
    description:
      "This application provides all the important data of SpaceX missions. Anyone also can quickly find details of any specific mission by using the search feature, filter feature available in this web application.",
    // technology:
    //   "React.js, Node.js, Express.js, MongoDB, Mongoose, Heroku, HTML, CSS.",
    technology: [
      "React.js",
      "React Router",
      "Redux Toolkit",
      "SpaceX-API",
      "Bootstrap",
      "HTML",
      "CSS",
    ],
    siteLink: "https://masrursakib-spacex.netlify.app/",
    githubLink: "https://github.com/masrur-sakib/spacex",
    disabled: "link",
  },
  {
    id: 3,
    title: "Fullstack Realtime Chat Application.",
    // img1: 'https://i.ibb.co/mHq6FgB/redux-aircnc-min.png',
    img1: "/images/projectsImages/chat-applicaton.png",
    description:
      "It’s a full-stack realtime chat application. This application is mainly developed using Node.js, Express.js, MongoDB, EJS etc. Role based authentication implemented and many more.",
    // technology:
    //   "React.js, Redux, React Router, JavaScript, Node.js, MongoDB, Heroku, Firebase, HTML, CSS, Bootstrap.",
    technology: [
      "Node.js",
      "Express.js",
      "JavaScript",
      "MongoDB",
      "Mongoose",
      "JWT (JSON Web Token)",
      "Moment.js",
      "Render",
    ],
    siteLink: "https://realtime-chat-application-tcda.onrender.com/",
    githubLink:
      "https://github.com/masrur-sakib/realtime-chat-backend-application",
    disabled: "link",
  },
  {
    id: 4,
    title: "Aircnc v1",
    // img1: 'https://i.ibb.co/mHq6FgB/redux-aircnc-min.png',
    img1: "/images/projectsImages/reduxAircncMin.png",
    description:
      "It's a Hotel booking website where you can book hotel rooms online from anywhere. This is mainly developed with React.js, Redux. Backend work has been done with Node.js, MongoDB & Heroku.",
    // technology:
    //   "React.js, Redux, React Router, JavaScript, Node.js, MongoDB, Heroku, Firebase, HTML, CSS, Bootstrap.",
    technology: [
      "React.js",
      "Redux",
      "JavaScript",
      "Node.js",
      "MongoDB",
      "Heroku",
      "Firebase",
      "Bootstrap",
    ],
    siteLink: "https://masrursakib-react-redux-aircnc.web.app/",
    githubLink:
      "https://github.com/masrur-sakib/react-redux-aircnc-hotel-booking-app",
    disabled: "link",
  },
  // {
  //   id: 2,
  //   title: 'Full Stack E-commerce Project',
  //   // img1: "https://i.imgur.com/76oYd1A.png",
  //   // img1: 'https://i.ibb.co/k3ZFqnk/fullstack-ecommerce-min.png',
  //   img1: '/images/projectsImages/fullstackEcommerceMin.png',
  //   description:
  //     'It’s a full-stack e-commerce website from where people can buy electronics gadgets and accessories. People also can order home delivery by making online payment.',
  //   // technology:
  //   //   "React.js, Node.js, Express.js, MongoDB, Heroku, Firebase Auth, Bootstrap.",
  //   technology: [
  //     'React.js',
  //     'Node.js',
  //     'Express.js',
  //     'MongoDB',
  //     'Heroku',
  //     'Firebase Auth',
  //     'Bootstrap',
  //   ],
  //   siteLink: 'https://masrursakib-fullstack-ecommerce.netlify.app/',
  //   githubLink: 'https://github.com/masrur-sakib/fullstack-ecommerce-project',
  //   disabled: 'link',
  // },
  {
    id: 5,
    title: "Full Stack Photography Project",
    // img1: 'https://i.ibb.co/vdyfrXH/fullstack-photography-project-min.png',
    img1: "/images/projectsImages/fullstackPhotographyProjectMin.png",
    description:
      "It’s a full-stack photography web application. I developed this application frontend using React.js and backend using Node.js, Express.js. I used NoSQL database MongoDB for this application.",
    // technology:
    //   "React.js, Node.js, Express.js, MongoDB, Mongoose, Heroku, HTML, CSS.",
    technology: [
      "React.js",
      "Node.js",
      "Express.js",
      "MongoDB",
      "Mongoose",
      "Heroku",
      "HTML",
      "CSS",
    ],
    siteLink: "https://masrursakib-photography-project.netlify.app/",
    githubLink: "https://github.com/masrur-sakib/fullstack-photography-project",
    disabled: "link",
  },
  {
    id: 6,
    title: "Quiz App using React & TypeScript",
    // img1: 'https://i.ibb.co/WtRpzy2/Portfolio-Quiz-App-short-min.png',
    img1: "/images/projectsImages/quizAppMin.png",
    description:
      "In this app, quizzes are provided in multiple-choice form. Score, Correct & Incorrect answer is displayed immediately when someone answers a question.",
    // technology: "React.js, TypeScript, HTML, Styled Components Library.",
    technology: ["React.js", "Styled Components", "TypeScript", "HTML"],
    siteLink: "https://masrursakib-react-typescript-quiz-app.netlify.app/",
    githubLink: "https://github.com/masrur-sakib/react-typescript-quiz-app",
    disabled: "link",
  },
  {
    id: 7,
    title: "GitHub API Project with React.js",
    // img1: 'https://i.ibb.co/HP7p8ws/Github-API-Project-with-React-js.png',
    img1: "/images/projectsImages/githubAPIProjectWithReact.js.png",
    description:
      "It’s a Github Profile Finder web app. All the public information of a GitHub Profile can be found using this web app.",
    // technology:
    //   "GitHub API, React.js, JavaScript, HTML, CSS, Bootstrap, Font awesome icons.",
    technology: [
      "GitHub API",
      "React.js",
      "JavaScript",
      "HTML",
      "CSS",
      "Bootstrap",
      "Font Awesome",
    ],
    siteLink: "https://masrursakib-react-github-api.netlify.app/",
    githubLink:
      "https://github.com/masrur-sakib/github-api-project-with-react.js",
    disabled: "link",
  },
  {
    id: 8,
    title: "Countries API React Application",
    // img1: 'https://i.ibb.co/FYQv4mW/Countries-API-App-min.png',
    img1: "/images/projectsImages/countriesAPIAppMin.png",
    description:
      "This Application displays key information of all countries using Countries REST API & React.js. All key information displayed based on dropdown selection.",
    // technology: "React.js, JavaScript, HTML, CSS, Bootstrap",
    technology: ["React.js", "JavaScript", "HTML", "CSS", "Bootstrap"],
    siteLink:
      "https://masrursakib-countries-api-react-application.netlify.app/",
    githubLink:
      "https://github.com/masrur-sakib/countries-api-react-application",
    disabled: "link",
  },
  // {
  //   id: 8,
  //   title: 'Sylhet Shop using React & Redux',
  //   // img1: 'https://i.ibb.co/9bQ4wZh/sylhet-shop-min.png',
  //   img1: '/images/projectsImages/sylhetShopMin.png',
  //   description:
  //     'This eCommerce Project is developed using React.js & Redux. In this store people can add product to the cart get instant summary of subtotal and total price.',
  //   // technology:
  //   //   "React.js, Redux, React Router, JavaScript, HTML, CSS, Bootstrap.",
  //   technology: [
  //     'React.js',
  //     'Redux',
  //     'React Router',
  //     'JavaScript',
  //     'HTML',
  //     'CSS',
  //     'Bootstrap',
  //   ],
  //   siteLink:
  //     'https://masrursakib-react-redux-sylhet-shop-ecommerce-application.netlify.app/',
  //   githubLink:
  //     'https://github.com/masrur-sakib/react-redux-ecommerce-sylhet-shop-application',
  //   disabled: 'link',
  // },
  // {
  //   id: 9,
  //   title: 'Vue.js App',
  //   // img1: 'https://i.ibb.co/C8XPc86/Vue-js-Todo-List-App.png',
  //   img1: '/images/projectsImages/vue.jsTodoListApp.png',
  //   description:
  //     "It's a To Do App developed using vue.js technology. In this app people can add new To Do, Mark an existing To Do done & Remove To Do from the list",
  //   // technology: "Vue.js, Vuex, Element UI, JavaScript, HTML,CSS",
  //   technology: ['Vue.js', 'Vuex', 'Element UI', 'JavaScript', 'HTML', 'CSS'],
  //   siteLink: 'https://masrursakib-vuejs-todo-list-app.netlify.app/',
  //   githubLink: 'https://github.com/masrur-sakib/vue.js-to-do-list-app',
  //   disabled: 'link',
  // },
  // {
  //   id: 10,
  //   title: 'Hot Gadgets Web App',
  //   // img1: 'https://i.ibb.co/2ZHTf5x/hot-gadgets-min.png',
  //   img1: '/images/projectsImages/hotGadgetsMin.png',
  //   description:
  //     'It’s a simple e-commerce where attractive UI has been implemented. Slideshow & social icons are used in this project. This website also contains all the necessary information about the business.',
  //   // technology: "HTML, CSS, Bootstrap CSS, Bootstrap JS, Font awesome icons.",
  //   technology: ['HTML', 'CSS', 'Bootstrap', 'Font Awesome'],
  //   siteLink: 'https://masrur-sakib.github.io/Hot-Gadgets/',
  //   githubLink: 'https://github.com/masrur-sakib/Hot-Gadgets',
  //   disabled: 'link',
  // },
  // {
  //   id: 10,
  //   title: "Panda Commerce",
  //   img1: "https://i.ibb.co/1JXD8hf/panda-commerce-min.png",
  //   description:
  //     "It’s a simple e-commerce where attractive UI has been implemented. Slideshow & social icons are used in this project. This website also contains all the necessary information about the business.",
  //   // technology: "HTML, CSS, Bootstrap CSS, Bootstrap JS, Font awesome icons.",
  //   technology: ["HTML", "CSS", "Bootstrap", "Bootstrap", "Font Awesome"],
  //   siteLink: "https://masrur-sakib.github.io/Panda-Commerce/",
  //   githubLink: "https://github.com/masrur-sakib/Panda-Commerce",
  //   disabled: "link",
  // },
];

export default projectsData;
