import React from 'react';
import './Project.css';

const Project = (props) => {
  const cssStyle = {
    width: '100%',
    height: '200px',
    backgroundImage: `url(${props.project.img1})`,
  };
  return (
    <div>
      <div className='card project-card'>
        <div style={cssStyle} className='project-card-img-section'></div>
        <div className='card-body'>
          <h5 className='project-title'>{props.project.title}</h5>
          <p className='project-description'>{props.project.description}</p>
          <div className='project-technology'>
            <div className='technology-section'>
              {props.project.technology.map((tech, index) => (
                <p key={index}>{tech}</p>
              ))}
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <a
              id={props.project.disabled}
              className='btn btn-github-link project-button'
              href={props.project.githubLink}
              role='button'
              target='_blank'
              rel='noreferrer'
            >
              <span>Code</span>
            </a>
            <a
              className='btn btn-site-link project-button'
              href={props.project.siteLink}
              role='button'
              target='_blank'
              rel='noreferrer'
            >
              Site Link
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
