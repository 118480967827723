import "./App.css";
import Header from "./components/Header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./components/HomePage/HomePage";
import BlogJavaScript from "./components/BlogPosts/BlogJavaScript/BlogJavaScript";
import BlogReact from "./components/BlogPosts/BlogReact/BlogReact";
import BlogVue from "./components/BlogPosts/BlogVue/BlogVue";
import BlogAlgorithm from "./components/BlogPosts/BlogAlgorithm/BlogAlgoritm";
import BlogDataStructure from "./components/BlogPosts/BlogDataStructure/BlogDataStructure";
import NotFound from "./components/NotFound/NotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Switch>
          <Route exact path="/">
            <HomePage></HomePage>
          </Route>
          <Route path="/blog/javascript-blog">
            <BlogJavaScript></BlogJavaScript>
          </Route>
          <Route path="/blog/react-blog">
            <BlogReact></BlogReact>
          </Route>
          <Route path="/blog/vue-blog">
            <BlogVue></BlogVue>
          </Route>
          <Route path="/blog/algorithm-blog">
            <BlogAlgorithm></BlogAlgorithm>
          </Route>
          <Route path="/blog/data-structure-blog">
            <BlogDataStructure></BlogDataStructure>
          </Route>
          <Route path="*">
            <NotFound></NotFound>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
