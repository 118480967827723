const frontendSkills = [
  {
    id: 1,
    title: 'JavaScript',

    img1: '/images/skillsImages/frontend/javascript.png',
  },
  {
    id: 2,
    title: 'ES6',
    img1: '/images/skillsImages/frontend/es6.png',
  },
  {
    id: 3,
    title: 'TypeScript',
    img1: '/images/skillsImages/frontend/typescript.png',
  },
  {
    id: 4,
    title: 'React.js',
    img1: '/images/skillsImages/frontend/react.png',
  },
  {
    id: 5,
    title: 'Next.js',
    img1: '/images/skillsImages/frontend/nextJs1.png',
  },
  {
    id: 6,
    title: 'Redux',
    img1: '/images/skillsImages/frontend/redux.png',
  },
  {
    id: 7,
    title: 'Vue.js',
    img1: '/images/skillsImages/frontend/vuejs.png',
  },
  {
    id: 8,
    title: 'HTML',
    img1: '/images/skillsImages/frontend/html.png',
  },
  {
    id: 9,
    title: 'CSS',
    img1: '/images/skillsImages/frontend/css.png',
  },
  {
    id: 10,
    title: 'Sass',
    img1: '/images/skillsImages/frontend/sass.png',
  },
  {
    id: 11,
    title: 'Bootstrap',
    img1: '/images/skillsImages/frontend/bootstrap.png',
  },
  {
    id: 12,
    title: 'Tailwind',
    img1: '/images/skillsImages/frontend/tailwind.png',
  },
  {
    id: 13,
    title: 'Ant Design',
    img1: '/images/skillsImages/frontend/antDesign.png',
  },
  {
    id: 14,
    title: 'Material-UI',
    img1: '/images/skillsImages/frontend/material-ui.png',
  },
  {
    id: 15,
    title: 'Jest',
    img1: '/images/skillsImages/frontend/jest.png',
  },
];

export default frontendSkills;
