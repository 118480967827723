import React from 'react';
import "./NotFound.css";
import notFoundImg from "../../images/notFound.jpg";

const NotFound = () => {
    return (
        <div>
            <div className="NotFound">
            <img src={notFoundImg} alt=""/>
        </div>
        </div>
    );
};

export default NotFound;