import React, { useEffect } from "react";
import "./BlogVue.css";

const BlogVue = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-page-body">
      <div className="container blog-content-body">
        {/* Blog Title */}
        <div className="blog-title">
          <h1>Vue.js Blog</h1>
        </div>

        {/* Blog Content  */}
        <div className="blog-content">
          <h5 className="mt-4">What is Vue?</h5>
          <ul>
            <li>
              Vue (or Vue.js) is an open-source front-end JavaScript framework
            </li>
            <li>
              Vue is the view layer of an MVC application (Model View
              Controller)
            </li>
            <li>
              Vue is currently one of the most popular JavaScript
              libraries/frameworks
            </li>
            <li>
              Vue was originally written by Evan You and the open-source
              community
            </li>
          </ul>

          <h5 className="mt-4">Vue.js Facilities:</h5>
          <ul>
            <li>Fast &amp; Lightweight</li>
            <li>Build Powerful SPA(Single Page Application) apps</li>
            <li>Virtual DOM</li>
            <li>Also uses component like react</li>
          </ul>

          <h5 className="mt-4">Setup and Installation</h5>
          <p>
            There are two main ways to set up Vue — in a Node project, or
            directly injected into a static HTML file.
          </p>
          <h6>Using CDN</h6>
          <p>
            Create a basic HTML file and add a the Vue CDN link in the head, and
            create a &lt;div&gt; element with an id of app.
          </p>
          <pre className="code-block">
            &lt;!DOCTYPE html&gt;
            <br />
            &lt;html lang="en"&gt;
            <br />
            &lt;head&gt;
            <br />
            &lt;meta charset="utf-8" /&gt;
            <br />
            &lt;meta http-equiv="X-UA-Compatible" content="IE=edge" /&gt;
            <br />
            &lt;meta name="viewport"
            content="width=device-width,initial-scale=1.0" /&gt;
            <br />
            &lt;script
            src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"&gt;&lt;/script&gt;
            <br />
            &lt;title&gt;Vue Application&lt;/title&gt;
            <br />
            &lt;/head&gt;
            <br />
            &lt;body&gt;
            <br />
            &lt;div id="app"&gt;
            <br />
            &lt;/div&gt;
            <br />
            &lt;/body&gt;
            <br />
            &lt;/html&gt;
          </pre>

          <h5 className="mt-4">Using Vue CLI</h5>
          <p>First, we’ll install Vue CLI</p>
          <pre className="code-block">
            # install with npm
            <br />
            npm i -g @vue/cli @vue/cli-service-global# install with yarn
            <br />
            # install with yarn
            <br />
            yarn global add @vue/cli @vue/cli-service-global
          </pre>
          <p>
            We have installed Vue CLI globally, we can use the vue command
            anywhere. We’ll use vue create to start a new project.
          </p>
          <p>
            <b>vue create</b> is the equivalent to <b>create-react-app.</b>
          </p>
          <pre className="code-block">vue create vue-app</pre>
          <p>In the next step we can just select default.</p>
          <pre className="code-block">
            Vue CLI v3.7.0
            <br />
            ? Please pick a preset: (Use arrow keys)
            <br />
            ❯ default (babel, eslint)
            <br />
            Manually select features
          </pre>
          <p>
            Once that’s done, you can move to the new app that’s been created
            and serve to run the dev server.
          </p>
          <pre className="code-block">
            cd vue-app
            <br />
            npm run serve
            <br />
            # or
            <br />
            yarn serve
          </pre>
          <p>
            Once that’s done, you can navigate to http://localhost:8080/ to see
            the default page.
          </p>
          <p>
            At this point, you’re all set up and ready to go with Vue. If you’re
            using VS Code, install the Vetur extension for syntax highlighting,
            formatting, and so on.
          </p>

          <h5 className="mt-4">Vue.js Directives</h5>
          <p>
            Vue.js uses double braces &#x7b;&#x7b; &#x7d;&#x7d; as place-holders
            for data.
          </p>
          <p>Vue.js directives are HTML attributes with the prefix v-</p>
          <h6>Vue Example</h6>
          <p>
            In the example below, a new Vue object is created with new Vue().
          </p>
          <p>
            The property el: binds the new Vue object to the HTML element with
            id=”app”.
          </p>
          <pre className="code-block">
            &#x3c;script&#x3e;
            <br />
            &#x3c;div id="app"&#x3e;
            <br />
            &#x3c;h1&#x3e;&#x7b;&#x7b; message &#x7d;&#x7d; &#x3c;/h1&#x3e;
            <br />
            &#x3c;/div&#x3e;
            <br />
            &#x3c;script&#x3e;
            <br />
            var myObject = new Vue(&#x7b;
            <br />
            el: '#app',
            <br />
            data: &#x7b;message: 'Hello Vue!'&#x7d;
            <br />
            &#x7d;)
            <br />
            &#x3c;/script&#x3e;
          </pre>

          <h5 className="mt-4">Vue.js Binding</h5>
          <p>
            When a Vue object is bound to an HTML element, the HTML element will
            change when the Vue object changes:
          </p>
          <pre className="code-block">
            &#x3c;div id="app"&#x3e;
            <br />
            &#x7b;&#x7b; message &#x7d;&#x7d;
            <br />
            &#x3c;/div&#x3e;
            <br />
            &#x3c;script&#x3e;
            <br />
            var myObject = new Vue(&#x7b;
            <br />
            el: "#app",
            <br />
            data: &#x7b; message: "Hello Vue!" &#x7d;,
            <br />
            &#x7d;);
            <br />
            function myFunction() &#x7b;
            <br />
            myObject.message = "John Doe";
            <br />
            &#x7d;
            <br />
            &#x3c;/script&#x3e;
            <br />
          </pre>

          <h5 className="mt-4">Vue.js Two-Way Binding</h5>
          <p>
            The v-model directive binds the value of HTML elements to
            application data.
          </p>
          <p>This is called two-way binding:</p>
          <pre className="code-block">
            &#x3c;div id="app"&#x3e;
            <br />
            &#x3c;p&#x3e;&#x7b;&#x7b; message &#x7d;&#x7d;&#x3c;/p&#x3e;
            <br />
            &#x3c;p&#x3e;&#x3c;input v-model="message"/&#x3e;&#x3c;/p&#x3e;
            <br />
            &#x3c;/div&#x3e;
            <br />
            &#x3c;script&#x3e;
            <br />
            var myObject = new Vue(&#x7b;
            <br />
            el: "#app",
            <br />
            data: &#x7b; message: "Hello Vue!" &#x7d;,
            <br />
            &#x7d;);
            <br />
            &#x3c;/script&#x3e;
          </pre>

          <h5 className="mt-4">Vue.js Loop Binding</h5>
          <p>
            Using the v-for directive to bind an array of Vue objects to an
            “array” of HTML element:
          </p>
          <pre className="code-block">
            &#x3c;div id="app"&#x3e;
            <br />
            &#x3c;ul&#x3e;
            <br />
            &#x3c;li v-for="x in todos"&#x3e;
            <br />
            &#x7b;&#x7b; x.text &#x7d;&#x7d;
            <br />
            &#x3c;/li&#x3e;
            <br />
            &#x3c;/ul&#x3e;
            <br />
            &#x3c;/div&#x3e;
            <br />
            &#x3c;script&#x3e;
            <br />
            myObject = new Vue(&#x7b;
            <br />
            el: "#app",
            <br />
            data: &#x7b;
            <br />
            todos: [
            <br />
            &#x7b; text: "Learn JavaScript" &#x7d;,
            <br />
            &#x7b; text: "Learn Vue.js" &#x7d;,
            <br />
            &#x7b; text: "Build Something Awesome" &#x7d;,
            <br />
            ],
            <br />
            &#x7d;,
            <br />
            &#x7d;);
            <br />
            &#x3c;/script&#x3e;
          </pre>
        </div>
      </div>
    </div>
  );
};

export default BlogVue;
