import React, { useEffect } from "react";
import "./BlogAlgorithm.css";

const BlogAlgoritm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blog-page-body">
      <div className="container blog-content-body">
        {/* Blog Title */}
        <div className="blog-title">
          <h1>Algorithm Blog</h1>
        </div>

        {/* Blog Content  */}
        <div className="blog-content">
          <h5 className="mt-4">Algorithms</h5>
          <p>
            An algorithm is an unambiguous specification of how to solve a class
            of problems. It is a set of rules that precisely define a sequence
            of operations.
          </p>
          <h5 className="mt-4">Examples:</h5>
          <p>
            <b>Brute Force</b> — look at all the possibilities and selects the
            best solution
          </p>
          <ul>
            <li>Linear Search</li>
            <li>Recursive Staircase</li>
          </ul>
          <p>
            <b>Greedy</b> — choose the best option at the current time, without
            any consideration for the future
          </p>
          <ul>
            <li>Jump Game</li>
            <li>Prim’s Algorithm</li>
          </ul>
          <p>
            <b>Divide and Conquer</b> — divide the problem into smaller parts
            and then solve those parts
          </p>
          <ul>
            <li>Binary Search</li>
            <li>Binary Search</li>
          </ul>
          <p>
            <b>Dynamic Programming</b> — build up a solution using previously
            found sub-solutions
          </p>
          <ul>
            <li>Fibonacci Number</li>
            <li>Jump Game</li>
          </ul>

          <p>
            <b>Backtracking</b> — similarly to brute force, try to generate all
            possible solutions, but each time you generate next solution you
            test if it satisfies all conditions, and only then continue
            generating subsequent solutions. Otherwise, backtrack, and go on a
            different path of finding a solution.
          </p>
          <ul>
            <li>Jump Game</li>
            <li>Unique Paths</li>
          </ul>

          <p>
            <b>Factorial</b>
          </p>
          <p>
            In mathematics, the factorial of a non-negative integer n, denoted
            by n!, is the product of all positive integers less than or equal to
            n. For example:
          </p>
          <pre className="code-block">5! = 5 * 4 * 3 * 2 * 1 = 120</pre>

          <p>
            <b>Fibonacci Number</b>
          </p>
          <p>
            The Fibonacci numbers are the numbers in the following integer
            sequence, called the Fibonacci sequence, and characterized by the
            fact that every number after the first two is the sum of the two
            preceding ones:
          </p>
          <pre className="code-block">
            0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, ..
          </pre>

          <p>
            <b>Linear Search</b>
          </p>
          <p>
            In computer science, linear search or sequential search is a method
            for finding a target value within a list. It sequentially checks
            each element of the list for the target value until a match is found
            or until all the elements have been searched. Linear search runs in
            at worst linear time and makes at most n comparisons, where n is the
            length of the list.
          </p>

          <p>
            <b>Linear Search</b>
          </p>
          <p>
            In computer science, linear search or sequential search is a method
            for finding a target value within a list. It sequentially checks
            each element of the list for the target value until a match is found
            or until all the elements have been searched. Linear search runs in
            at worst linear time and makes at most n comparisons, where n is the
            length of the list.
          </p>

          <p>
            <b>Bubble Sort</b>
          </p>
          <p>
            Bubble sort, sometimes referred to as sinking sort, is a simple
            sorting algorithm that repeatedly steps through the list to be
            sorted, compares each pair of adjacent items and swaps them if they
            are in the wrong order (ascending or descending arrangement). The
            pass through the list is repeated until no swaps are needed, which
            indicates that the list is sorted.
          </p>

          <p>
            <b>Selection Sort</b>
          </p>
          <p>
            Selection sort is a sorting algorithm, specifically an in-place
            comparison sort. It has O(n2) time complexity, making it inefficient
            on large lists, and generally performs worse than the similar
            insertion sort. Selection sort is noted for its simplicity, and it
            has performance advantages over more complicated algorithms in
            certain situations, particularly where auxiliary memory is limited.
          </p>

          <p>
            <b>Insertion Sort</b>
          </p>
          <p>
            Insertion sort is a simple sorting algorithm that builds the final
            sorted array (or list) one item at a time. It is much less efficient
            on large lists than more advanced algorithms such as quicksort,
            heapsort, or merge sort.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogAlgoritm;
