const backendSkills = [
  {
    id: 1,
    title: "Node.js",

    img1: "/images/skillsImages/backend/node-js.png",
  },
  {
    id: 2,
    title: "Express.js",
    img1: "/images/skillsImages/backend/expressjs.png",
  },
  {
    id: 3,
    title: "Hapi",
    img1: "/images/skillsImages/backend/hapi.png",
  },
  {
    id: 4,
    title: "MongoDB",
    img1: "/images/skillsImages/backend/mongodb.png",
  },
  {
    id: 5,
    title: "Mongoose",
    img1: "/images/skillsImages/backend/mongoose.png",
  },
  {
    id: 6,
    title: "Heroku",
    img1: "/images/skillsImages/backend/heroku.png",
  },
  {
    id: 7,
    title: "REST API",
    img1: "/images/skillsImages/backend/rest-api.jpg",
  },
  {
    id: 8,
    title: "FastAPI",
    img1: "/images/skillsImages/backend/FastAPI.png",
  },
  {
    id: 9,
    title: "GraphQL",
    img1: "/images/skillsImages/backend/graphql.png",
  },
  {
    id: 10,
    title: "Swagger",
    img1: "/images/skillsImages/backend/swagger.png",
  },
  {
    id: 11,
    title: "Mocha",
    img1: "/images/skillsImages/backend/mocha.png",
  },
  {
    id: 12,
    title: "Chai",
    img1: "/images/skillsImages/backend/chai.png",
  },
  // {
  //   id: 12,
  //   title: 'JWT',
  //   img1: '/images/skillsImages/backend/jwt.png',
  // },
];

export default backendSkills;
