import React from "react";
import homeRightSectionImg from "../../images/home-right-section-img.png";
import "./Home.css";
import { FaLinkedinIn, FaGithub, FaBehance, FaEnvelope } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

const Home = () => {
  return (
    <div className="home-section">
      <div className="container">
        <div className="home-container-section">
          <div className="home-left-section">
            <div>
              <h1 className="developer-name">Masrur Rahman Sakib</h1>
              <h4 className="developer-title">Full Stack Software Engineer</h4>
              <div className="mb-4 social-links">
                <a
                  className="linkedin-link"
                  href="https://www.linkedin.com/in/masrur-sakib/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn className="social-icons linkedin-icon" />
                </a>
                <a
                  className="github-link"
                  href="https://github.com/masrur-sakib"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="social-icons github-icon" />
                </a>
                {/* <a
                  className="behance-link"
                  href="https://leetcode.com/mashrur333/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiLeetcode className="social-icons behance-icon" />
                </a> */}
                {/* <a
                  className="behance-link"
                  href="https://www.behance.net/masrursakib"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaBehance className="social-icons behance-icon" />
                </a> */}
                <a
                  className="mail-link"
                  href="mailto:mashrur333@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaEnvelope className="social-icons mail-icon" />
                </a>
              </div>
              <div className="home-sections-buttons">
                <a className="btn btn-contact" href="#contact" role="button">
                  Contact Me
                </a>
              </div>
            </div>
          </div>
          <div className="home-right-section">
            <img
              className="home-right-section-img"
              src={homeRightSectionImg}
              alt="home-right-section-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
